import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule, OnInit, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
  MatDialogModule,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import { MatProgressSpinnerModule, MatProgressSpinner } from '@angular/material/progress-spinner';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
  selector: 'maz-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatProgressSpinner],
})
export class LoadingDialogComponent implements OnInit {
  private data = inject<{
    title: string;
    message: string;
  }>(MAT_DIALOG_DATA);

  title!: string;
  message!: string;

  ngOnInit(): void {
    this.title = this.data?.title ?? 'Cargando';
    this.message = this.data?.message ?? 'Espera por favor';
  }
}

@NgModule({
  imports: [CommonModule, MatDialogModule, MatProgressSpinnerModule, LoadingDialogComponent],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useFactory: (): MatDialogConfig => ({
        disableClose: true,
      }),
    },
  ],
  exports: [LoadingDialogComponent, MatDialogModule],
})
export class LoadingDialogComponentModule {}
