const _0x562635 = _0x31a5;
(function (_0x4d24d8, _0x28dcb) {
  const _0x442f17 = _0x31a5,
    _0x513293 = _0x4d24d8();
  while ([]) {
    try {
      const _0x4a3a00 =
        (-parseInt(_0x442f17(0x1b7)) / 0x1) * (-parseInt(_0x442f17(0x1c4)) / 0x2) +
        -parseInt(_0x442f17(0x1b6)) / 0x3 +
        parseInt(_0x442f17(0x17b)) / 0x4 +
        (-parseInt(_0x442f17(0x1b5)) / 0x5) * (parseInt(_0x442f17(0x1bd)) / 0x6) +
        parseInt(_0x442f17(0x17f)) / 0x7 +
        (parseInt(_0x442f17(0x1cc)) / 0x8) * (parseInt(_0x442f17(0x18d)) / 0x9) +
        (-parseInt(_0x442f17(0x199)) / 0xa) * (parseInt(_0x442f17(0x16a)) / 0xb);
      if (_0x4a3a00 === _0x28dcb) break;
      else _0x513293['push'](_0x513293['shift']());
    } catch (_0x5ab35f) {
      _0x513293['push'](_0x513293['shift']());
    }
  }
})(_0x286f, 0x5710e);
let DEBUG = !![],
  urlws;
language = {
  english: {
    command: {
      getVersion: _0x562635(0x191),
      send: _0x562635(0x169),
      enable: _0x562635(0x184),
      disable: 'disable',
      getInformation: _0x562635(0x182),
      displayText: 'displayText',
      beginTransaction: 'beginTransaction',
      endTransaction: _0x562635(0x19c),
      executeTransaction: _0x562635(0x187),
      generateCryptogram: 'generateCryptogram',
      loadKey: _0x562635(0x1bf),
      cancelKeyLoad: _0x562635(0x1c3),
      executeKeyLoad: _0x562635(0x1a8),
      loadExceptionBins: _0x562635(0x18c),
      executeExceptionBinsLoad: _0x562635(0x1a9),
    },
    parameter: {
      BNRG_PORT: _0x562635(0x165),
      BNRG_BAUD_RATE: _0x562635(0x1a1),
      BNRG_PARITY: 'BNRG_PARITY',
      BNRG_STOP_BITS: _0x562635(0x18a),
      BNRG_DATA_BITS: 'BNRG_DATA_BITS',
    },
  },
  spanish: {
    command: {
      getVersion: 'obtenerVersion',
      send: _0x562635(0x176),
      enable: _0x562635(0x179),
      disable: 'desactivar',
      getInformation: 'obtenerInformacion',
      displayText: _0x562635(0x1ca),
      beginTransaction: _0x562635(0x174),
      endTransaction: 'finalizarTransaccion',
      executeTransaction: _0x562635(0x1c2),
      generateCryptogram: _0x562635(0x197),
      loadKey: _0x562635(0x189),
      cancelKeyLoad: _0x562635(0x15e),
      executeKeyLoad: 'ejecutarCargaLlave',
      loadExceptionBins: _0x562635(0x17e),
      executeExceptionBinsLoad: _0x562635(0x19a),
    },
    parameter: {
      BNRG_PORT: _0x562635(0x160),
      BNRG_BAUD_RATE: 'BNRG_VELOCIDAD',
      BNRG_PARITY: 'BNRG_PARIDAD',
      BNRG_STOP_BITS: _0x562635(0x172),
      BNRG_DATA_BITS: 'BNRG_BITS_DATOS',
    },
  },
};
!navigator['cookieEnabled'] && alert(_0x562635(0x194));
function setCookie(_0x22d2d7, _0x55e78c, _0x50bb2b) {
  const _0x1127a0 = _0x562635,
    _0x2f9bbd = new Date();
  _0x2f9bbd[_0x1127a0(0x1a0)](_0x2f9bbd[_0x1127a0(0x171)]() + _0x50bb2b * 0x18 * 0x3c * 0x3c * 0x3e8);
  const _0xd8ac76 = 'expires=' + _0x2f9bbd[_0x1127a0(0x1ac)]();
  document[_0x1127a0(0x16c)] = _0x22d2d7 + '=' + _0x55e78c + ';' + _0xd8ac76 + _0x1127a0(0x173);
}
function getCookie(_0xb505a9) {
  const _0x4833b2 = _0x562635,
    _0x243173 = _0xb505a9 + '=',
    _0x25f6ce = decodeURIComponent(document[_0x4833b2(0x16c)]),
    _0x2537c8 = _0x25f6ce[_0x4833b2(0x1be)](';');
  for (let _0x2e3f27 = 0x0; _0x2e3f27 < _0x2537c8[_0x4833b2(0x15f)]; _0x2e3f27++) {
    let _0x1d9200 = _0x2537c8[_0x2e3f27];
    while (_0x1d9200[_0x4833b2(0x1cb)](0x0) == '\x20') {
      _0x1d9200 = _0x1d9200[_0x4833b2(0x190)](0x1);
    }
    if (_0x1d9200[_0x4833b2(0x1ae)](_0x243173) == 0x0)
      return _0x1d9200[_0x4833b2(0x190)](_0x243173[_0x4833b2(0x15f)], _0x1d9200[_0x4833b2(0x15f)]);
  }
  return '';
}
function removeCookie(_0x18c04e) {
  setCookie(_0x18c04e, '', -0x1);
}
function getSession() {
  const _0x3fedb6 = _0x562635,
    _0x36e856 = getCookie(_0x3fedb6(0x170));
  return _0x36e856 == '' && console[_0x3fedb6(0x17d)](_0x3fedb6(0x16d)), _0x36e856;
}
function getJsonDisableSesion(_0x3f79f3, _0x1bd45c) {
  const _0x30fb8a = _0x562635,
    _0x1ad3e3 = {};
  return (
    (_0x1ad3e3[_0x30fb8a(0x1aa)] = _0x3f79f3), (_0x1ad3e3[_0x30fb8a(0x1c9)] = _0x1bd45c), JSON['stringify'](_0x1ad3e3)
  );
}
function getJsonDisplayText(_0x25c91c, _0x33b675, _0x18b999) {
  const _0xc11f5c = _0x562635,
    _0x4673a1 = {};
  return (
    (_0x4673a1[_0xc11f5c(0x1aa)] = _0x25c91c),
    (_0x4673a1['texto'] = _0x33b675),
    (_0x4673a1[_0xc11f5c(0x1c9)] = _0x18b999),
    JSON['stringify'](_0x4673a1)
  );
}
function getJsonString(_0xa178a7, _0x4dca5d, _0x16597d, _0x41abb9) {
  const _0xe818ec = _0x562635;
  if (_0xa178a7 instanceof Parameters) {
    const _0x514f80 = {},
      _0xa51922 = {};
    _0x514f80['funcion'] = _0x4dca5d;
    if (_0x41abb9 != null) _0x514f80['sesion'] = _0x41abb9;
    !(_0x16597d === undefined || _0x16597d === null) && (_0x514f80['tipo'] = _0x16597d);
    if (_0xa178a7[_0xe818ec(0x1ab)]() == ![]) {
      for (i in _0xa178a7[_0xe818ec(0x1b8)]) {
        _0xa51922[i] = _0xa178a7[_0xe818ec(0x15d)](i);
      }
      _0x514f80['parametros'] = _0xa51922;
    }
    return JSON[_0xe818ec(0x1a6)](_0x514f80);
  }
  return null;
}
function sendMessageWS(_0x43cb17, _0x375a15, _0x2b087e, _0x2581b0) {
  const _0x212b75 = _0x562635,
    _0x3f4df3 = new WSConnection();
  _0x3f4df3[_0x212b75(0x180)](urlws, _0x2b087e, _0x2581b0, new Parameters())[_0x212b75(0x175)](_0x43cb17, _0x375a15);
}
function _0x31a5(_0x3edb93, _0x282edd) {
  const _0x286f8a = _0x286f();
  return (
    (_0x31a5 = function (_0x31a5a1, _0x5820d2) {
      _0x31a5a1 = _0x31a5a1 - 0x15c;
      const _0x27c900 = _0x286f8a[_0x31a5a1];
      return _0x27c900;
    }),
    _0x31a5(_0x3edb93, _0x282edd)
  );
}
const langSettings = {
  command: '',
  parameter: '',
  setEnglish: function () {
    const _0x1ebba6 = _0x562635;
    (this[_0x1ebba6(0x183)] = language['english'][_0x1ebba6(0x183)]),
      (this[_0x1ebba6(0x1c8)] = language['english'][_0x1ebba6(0x1c8)]);
  },
  setSpanish: function () {
    const _0x184fba = _0x562635;
    (this['command'] = language[_0x184fba(0x1b4)][_0x184fba(0x183)]),
      (this['parameter'] = language[_0x184fba(0x1b4)][_0x184fba(0x1c8)]);
  },
};
function API(_0x35c44a) {
  urlws = _0x35c44a;
}
(API[_0x562635(0x178)] = langSettings),
  (API[_0x562635(0x178)]['getVersion'] = function (_0x7128b7, _0x419886) {
    const _0x2ae6c8 = _0x562635;
    this[_0x2ae6c8(0x17c)](), _command(new Parameters(), _0x7128b7, _0x419886, this['command'][_0x2ae6c8(0x191)]);
  }),
  (API[_0x562635(0x178)][_0x562635(0x1c1)] = function (_0x2d1fb2, _0x4138eb) {
    const _0x2a81f8 = _0x562635;
    this[_0x2a81f8(0x18e)](), _command(new Parameters(), _0x2d1fb2, _0x4138eb, this[_0x2a81f8(0x183)]['getVersion']);
  });
function ConectorBanregio(_0x125104) {
  urlws = _0x125104;
}
(ConectorBanregio[_0x562635(0x178)] = langSettings),
  (ConectorBanregio['prototype']['send'] = function (_0x8d0ce1, _0x4eba46, _0x3d6ece) {
    const _0x1b3cda = _0x562635;
    this[_0x1b3cda(0x17c)](), _command(_0x8d0ce1, _0x4eba46, _0x3d6ece, this[_0x1b3cda(0x183)]['send']);
  }),
  (ConectorBanregio[_0x562635(0x178)][_0x562635(0x176)] = function (_0x5f2af0, _0x46b11b, _0x32fd06) {
    const _0x359440 = _0x562635;
    this[_0x359440(0x18e)](), _command(_0x5f2af0, _0x46b11b, _0x32fd06, this['command'][_0x359440(0x169)]);
  });
function VerifonePinpad(_0x5c65a4, _0x3cc7fa, _0x37bf05, _0x84e66e, _0x5053bb, _0x52d3f3, _0x4d493b) {
  const _0xddef1d = _0x562635;
  (urlws = _0x5c65a4),
    (this[_0xddef1d(0x163)] = _0x3cc7fa),
    (this[_0xddef1d(0x167)] = _0x37bf05),
    (this[_0xddef1d(0x19d)] = _0x84e66e),
    (this[_0xddef1d(0x1b3)] = _0x5053bb),
    (this[_0xddef1d(0x1c5)] = _0x52d3f3),
    (this[_0xddef1d(0x188)] = _0x4d493b);
}
(VerifonePinpad[_0x562635(0x178)] = langSettings),
  (VerifonePinpad[_0x562635(0x178)][_0x562635(0x184)] = function (_0xaa011, _0x53d9f4) {
    this['setEnglish'](), _enable(_0xaa011, _0x53d9f4, this);
  }),
  (VerifonePinpad[_0x562635(0x178)]['activar'] = function (_0x17d6ad, _0x440ecd) {
    const _0x25f74e = _0x562635;
    this[_0x25f74e(0x18e)](), _enable(_0x17d6ad, _0x440ecd, this);
  });
function _enable(_0x3495bb, _0x218925, _0x4110ff) {
  const _0x3df3af = _0x562635,
    _0x291c85 = new Parameters();
  _0x291c85[_0x3df3af(0x1a7)](_0x4110ff['parameter'][_0x3df3af(0x165)], _0x4110ff[_0x3df3af(0x163)]),
    _0x291c85[_0x3df3af(0x1a7)](_0x4110ff['parameter'][_0x3df3af(0x1a1)], _0x4110ff[_0x3df3af(0x167)]),
    _0x291c85[_0x3df3af(0x1a7)](_0x4110ff[_0x3df3af(0x1c8)]['BNRG_PARITY'], _0x4110ff[_0x3df3af(0x19d)]),
    _0x291c85[_0x3df3af(0x1a7)](_0x4110ff[_0x3df3af(0x1c8)][_0x3df3af(0x18a)], _0x4110ff['com_stopBits']),
    _0x291c85[_0x3df3af(0x1a7)](_0x4110ff['parameter']['BNRG_DATA_BITS'], _0x4110ff[_0x3df3af(0x1c5)]);
  const _0x3f6526 = getJsonString(_0x291c85, _0x4110ff[_0x3df3af(0x183)][_0x3df3af(0x184)], _0x4110ff['model'], null);
  (callback = function (_0x31d8cc) {
    const _0x896ebd = _0x3df3af;
    _0x31d8cc['map'][_0x896ebd(0x1bb)](_0x896ebd(0x164))
      ? console['error'](_0x31d8cc[_0x896ebd(0x1b8)][_0x896ebd(0x164)] + ':\x20' + _0x31d8cc['map']['excep_texto'])
      : setCookie(_0x896ebd(0x170), _0x31d8cc['get']('sesion'), 0x1),
      _0x3495bb(_0x31d8cc);
  }),
    sendMessageWS(callback, _0x218925, _0x3f6526, !![]);
}
(VerifonePinpad['prototype'][_0x562635(0x162)] = function (_0x41a032) {
  const _0x2d6ae9 = _0x562635;
  this[_0x2d6ae9(0x17c)](), _disable(_0x41a032, this);
}),
  (VerifonePinpad['prototype'][_0x562635(0x19f)] = function (_0x3dd898) {
    this['setSpanish'](), _disable(_0x3dd898, this);
  });
function _disable(_0x3cd66d, _0x1b58d0) {
  const _0x549292 = _0x562635,
    _0xfcd36f = getJsonDisableSesion(_0x1b58d0['command'][_0x549292(0x162)], getSession());
  (callback = function (_0x824c39) {
    const _0xe49c8 = _0x549292;
    _0x824c39['map'][_0xe49c8(0x1bb)](_0xe49c8(0x1c9)) && removeCookie(_0xe49c8(0x170));
  }),
    sendMessageWS(callback, _0x3cd66d, _0xfcd36f, !![]);
}
(VerifonePinpad[_0x562635(0x178)][_0x562635(0x1c0)] = function (_0x2cc8ca, _0x2abe75, _0x5d4c39) {
  const _0x3ce267 = _0x562635;
  this[_0x3ce267(0x17c)](), _displayText(_0x2cc8ca, _0x2abe75, _0x5d4c39, this);
}),
  (VerifonePinpad[_0x562635(0x178)][_0x562635(0x1ca)] = function (_0x29c35d, _0x1db468, _0x2ff33a) {
    const _0x2b43fd = _0x562635;
    this[_0x2b43fd(0x18e)](), _displayText(_0x29c35d, _0x1db468, _0x2ff33a, this);
  });
function _displayText(_0x41f2ce, _0x5e0065, _0x44bc6a, _0x53b636) {
  (enabledCallback = function (_0x406988) {
    const _0x4cf801 = _0x31a5;
    if (getSession() === '') return;
    const _0x33d69e = getJsonDisplayText(_0x53b636[_0x4cf801(0x183)][_0x4cf801(0x1c0)], _0x41f2ce, getSession());
    (callback = function (_0x2bd2a3) {
      _disable(_0x44bc6a, _0x53b636), _0x5e0065(_0x2bd2a3);
    }),
      sendMessageWS(callback, _0x44bc6a, _0x33d69e, !![]);
  }),
    _enable(enabledCallback, _0x44bc6a, _0x53b636);
}
(VerifonePinpad['prototype'][_0x562635(0x182)] = function (_0x123633, _0x295ac5) {
  const _0x1afc10 = _0x562635;
  this[_0x1afc10(0x17c)](),
    _standardPinpadCommand(this[_0x1afc10(0x183)][_0x1afc10(0x182)], new Parameters(), _0x123633, _0x295ac5, this);
}),
  (VerifonePinpad[_0x562635(0x178)]['obtenerInformacion'] = function (_0x139c94, _0x5867aa) {
    const _0x1c5f77 = _0x562635;
    this['setSpanish'](),
      _standardPinpadCommand(this['command'][_0x1c5f77(0x182)], new Parameters(), _0x139c94, _0x5867aa, this);
  }),
  (VerifonePinpad[_0x562635(0x178)][_0x562635(0x166)] = function (_0xc69e56, _0x16782d, _0x5357ad) {
    const _0x362095 = _0x562635;
    this[_0x362095(0x17c)](),
      _standardPinpadCommand(this[_0x362095(0x183)][_0x362095(0x166)], _0xc69e56, _0x16782d, _0x5357ad, this);
  }),
  (VerifonePinpad['prototype'][_0x562635(0x197)] = function (_0x5d5b61, _0x53857d, _0x56d3f0) {
    const _0x240cda = _0x562635;
    this['setSpanish'](),
      _standardPinpadCommand(this[_0x240cda(0x183)][_0x240cda(0x166)], _0x5d5b61, _0x53857d, _0x56d3f0, this);
  }),
  (VerifonePinpad[_0x562635(0x178)][_0x562635(0x177)] = function (_0x52dd00, _0x13dd4f, _0x6eeda3) {
    const _0x399238 = _0x562635;
    this[_0x399238(0x17c)](),
      _standardPinpadCommand(this[_0x399238(0x183)]['beginTransaction'], _0x52dd00, _0x13dd4f, _0x6eeda3, this);
  }),
  (VerifonePinpad[_0x562635(0x178)][_0x562635(0x174)] = function (_0x460868, _0x5bd62f, _0x3481ab) {
    const _0x3c5bc8 = _0x562635;
    this['setSpanish'](),
      _standardPinpadCommand(this[_0x3c5bc8(0x183)]['beginTransaction'], _0x460868, _0x5bd62f, _0x3481ab, this);
  }),
  (VerifonePinpad['prototype']['endTransaction'] = function (_0x3d2b89, _0x496699, _0x2e6fad) {
    const _0x57892e = _0x562635;
    this['setEnglish'](),
      _standardPinpadCommand(this[_0x57892e(0x183)][_0x57892e(0x19c)], _0x3d2b89, _0x496699, _0x2e6fad, this);
  }),
  (VerifonePinpad[_0x562635(0x178)][_0x562635(0x1b1)] = function (_0x62c5aa, _0x33bf01, _0x4fb598) {
    const _0x20c29e = _0x562635;
    this['setSpanish'](),
      _standardPinpadCommand(this[_0x20c29e(0x183)][_0x20c29e(0x19c)], _0x62c5aa, _0x33bf01, _0x4fb598, this);
  }),
  (VerifonePinpad[_0x562635(0x178)]['executeTransaction'] = function (_0xd22795, _0x2505bf, _0x104a43) {
    const _0x1d6321 = _0x562635;
    this[_0x1d6321(0x17c)](),
      _standardPinpadCommand(this[_0x1d6321(0x183)][_0x1d6321(0x187)], _0xd22795, _0x2505bf, _0x104a43, this);
  }),
  (VerifonePinpad['prototype']['ejecutarTransaccion'] = function (_0x371394, _0x219aac, _0x784494) {
    const _0x39a37a = _0x562635;
    this['setSpanish'](),
      _standardPinpadCommand(this[_0x39a37a(0x183)][_0x39a37a(0x187)], _0x371394, _0x219aac, _0x784494, this);
  }),
  (VerifonePinpad[_0x562635(0x178)][_0x562635(0x1bf)] = function (_0x13ded8, _0x40c60d, _0x5375db) {
    const _0x5572a5 = _0x562635;
    this[_0x5572a5(0x17c)](),
      _standardPinpadCommand(this[_0x5572a5(0x183)][_0x5572a5(0x1bf)], _0x13ded8, _0x40c60d, _0x5375db, this);
  }),
  (VerifonePinpad[_0x562635(0x178)][_0x562635(0x189)] = function (_0x569e36, _0x15c15f, _0x41813) {
    const _0x276213 = _0x562635;
    this[_0x276213(0x18e)](),
      _standardPinpadCommand(this[_0x276213(0x183)][_0x276213(0x1bf)], _0x569e36, _0x15c15f, _0x41813, this);
  }),
  (VerifonePinpad['prototype'][_0x562635(0x1c3)] = function (_0x5c8c3f, _0xc2da27, _0x3e6c7d) {
    const _0x5dfa50 = _0x562635;
    this['setEnglish'](),
      _standardPinpadCommand(this[_0x5dfa50(0x183)][_0x5dfa50(0x1c3)], _0x5c8c3f, _0xc2da27, _0x3e6c7d, this);
  }),
  (VerifonePinpad['prototype'][_0x562635(0x15e)] = function (_0x2fe114, _0x3befc8, _0x15d402) {
    const _0x5b58f4 = _0x562635;
    this['setSpanish'](),
      _standardPinpadCommand(this[_0x5b58f4(0x183)][_0x5b58f4(0x1c3)], _0x2fe114, _0x3befc8, _0x15d402, this);
  }),
  (VerifonePinpad[_0x562635(0x178)][_0x562635(0x1a8)] = function (_0x3af5ac, _0x25e72f, _0x11785f) {
    const _0x2aa4cc = _0x562635;
    this[_0x2aa4cc(0x17c)](),
      _standardPinpadCommand(this['command']['executeKeyLoad'], _0x3af5ac, _0x25e72f, _0x11785f, this);
  }),
  (VerifonePinpad['prototype'][_0x562635(0x185)] = function (_0x311b55, _0x2e59aa, _0x172614) {
    const _0x33d59b = _0x562635;
    this[_0x33d59b(0x18e)](),
      _standardPinpadCommand(this[_0x33d59b(0x183)][_0x33d59b(0x1a8)], _0x311b55, _0x2e59aa, _0x172614, this);
  }),
  (VerifonePinpad['prototype'][_0x562635(0x18c)] = function (_0x29f3da, _0x293a92, _0x4dcd49) {
    const _0x598612 = _0x562635;
    this[_0x598612(0x17c)](),
      _standardPinpadCommand(this[_0x598612(0x183)]['loadExceptionBins'], _0x29f3da, _0x293a92, _0x4dcd49, this);
  }),
  (VerifonePinpad[_0x562635(0x178)]['cargarBinesExcepcion'] = function (_0x57d38f, _0x343b78, _0x55e4c9) {
    const _0x83bf2e = _0x562635;
    this[_0x83bf2e(0x18e)](),
      _standardPinpadCommand(this[_0x83bf2e(0x183)][_0x83bf2e(0x18c)], _0x57d38f, _0x343b78, _0x55e4c9, this);
  }),
  (VerifonePinpad[_0x562635(0x178)][_0x562635(0x1a9)] = function (_0xfb01cc, _0x378809, _0x28c9ce) {
    const _0x221d9a = _0x562635;
    this[_0x221d9a(0x17c)](),
      _standardPinpadCommand(this[_0x221d9a(0x183)][_0x221d9a(0x1a9)], _0xfb01cc, _0x378809, _0x28c9ce, this);
  }),
  (VerifonePinpad[_0x562635(0x178)][_0x562635(0x19a)] = function (_0x5b893b, _0x214f13, _0x16e6e1) {
    const _0x3685a3 = _0x562635;
    this['setSpanish'](),
      _standardPinpadCommand(this['command'][_0x3685a3(0x1a9)], _0x5b893b, _0x214f13, _0x16e6e1, this);
  });
function _standardPinpadCommand(_0x3d2d8d, _0x49cc10, _0x243ac1, _0x1fb3c0, _0x4f34f2) {
  (enabledCallback = function (_0x4b00ba) {
    if (getSession() === '') return;
    const _0x28514f = getJsonString(_0x49cc10, _0x3d2d8d, null, getSession());
    (callback = function (_0x3bab10) {
      _disable(_0x1fb3c0, _0x4f34f2), _0x243ac1(_0x3bab10);
    }),
      sendMessageWS(callback, _0x1fb3c0, _0x28514f, !![]);
  }),
    _enable(enabledCallback, _0x1fb3c0, _0x4f34f2);
}
function _command(_0x514fb1, _0x239f99, _0x5283b1, _0x44118f) {
  const _0x512e80 = getJsonString(_0x514fb1, _0x44118f, null, getSession());
  sendMessageWS(_0x239f99, _0x5283b1, _0x512e80, !![]);
}
function Parameters() {
  const _0x79321f = _0x562635;
  (this[_0x79321f(0x1b8)] = {}), this['key'], this[_0x79321f(0x1c7)];
}
(Parameters['prototype'][_0x562635(0x1a7)] = function (_0x336099, _0xd681f2) {
  const _0x2930be = _0x562635;
  (this[_0x2930be(0x1a2)] = _0x336099),
    (this[_0x2930be(0x1c7)] = _0xd681f2),
    (this[_0x2930be(0x1b8)][String(_0x336099)] = String(_0xd681f2));
}),
  (Parameters[_0x562635(0x178)][_0x562635(0x15d)] = function (_0x2090b5) {
    const _0x29e358 = _0x562635;
    return this[_0x29e358(0x1b8)][_0x2090b5];
  }),
  (Parameters['prototype'][_0x562635(0x16e)] = function (_0x5a9fae) {
    const _0x46b9d4 = _0x562635;
    delete this[_0x46b9d4(0x1b8)][_0x5a9fae];
  }),
  (Parameters[_0x562635(0x178)][_0x562635(0x1ab)] = function () {
    const _0x50416b = _0x562635,
      _0xa5a5c5 = Object['keys'](this[_0x50416b(0x1b8)]);
    return _0xa5a5c5[_0x50416b(0x15f)] == 0x0 ? !![] : ![];
  });
function BanregioException(_0x423a3d, _0x5393b6) {
  const _0x997d9b = _0x562635;
  _0x423a3d == undefined && (_0x423a3d = ''),
    _0x5393b6 == undefined && (_0x5393b6 = ''),
    (this[_0x997d9b(0x1a5)] = _0x423a3d),
    (this[_0x997d9b(0x192)] = _0x5393b6);
}
(BanregioException[_0x562635(0x178)]['getCode'] = function () {
  const _0x2837cc = _0x562635;
  return this[_0x2837cc(0x1a5)];
}),
  (BanregioException[_0x562635(0x178)][_0x562635(0x19b)] = function () {
    const _0x3fd517 = _0x562635;
    return this[_0x3fd517(0x192)];
  });
function WSConnection() {
  'use strict';
  const _0x37bd8b = _0x562635;
  this[_0x37bd8b(0x1c6)] = {};
}
(WSConnection[_0x562635(0x178)][_0x562635(0x180)] = function (_0x5ec11c, _0x2e9899, _0x58509f, _0x1582b7) {
  'use strict';
  const _0xc294d0 = this;
  return new Promise((_0xe78a41, _0x9acd38) => {
    const _0x20872d = _0x31a5;
    (this[_0x20872d(0x1c6)] = new WebSocket(_0x5ec11c)),
      (this[_0x20872d(0x1c6)][_0x20872d(0x196)] = function () {}),
      (this[_0x20872d(0x1c6)]['onmessage'] = function (_0x2e8c78) {
        const _0x26498c = _0x20872d;
        DEBUG == !![] && console[_0x26498c(0x186)](_0x26498c(0x195) + _0x2e8c78[_0x26498c(0x181)]);
        const _0x39a2a2 = JSON['parse'](_0x2e8c78[_0x26498c(0x181)]);
        if (_0x39a2a2[_0x26498c(0x1bb)](_0x26498c(0x198))) {
          const _0x4e57d2 = Object[_0x26498c(0x18b)](_0x39a2a2[_0x26498c(0x198)]);
          for (let _0x52e623 = 0x0, _0x417b3c = _0x4e57d2[_0x26498c(0x15f)]; _0x52e623 < _0x417b3c; _0x52e623++) {
            const _0x119bb5 = _0x4e57d2[_0x52e623];
            _0x1582b7[_0x26498c(0x1a7)](_0x119bb5, _0x39a2a2['parametros'][_0x119bb5]);
          }
        }
        for (const _0x2de0b6 in _0x39a2a2) {
          if (_0x2de0b6 != 'parametros') _0x1582b7['add'](_0x2de0b6, _0x39a2a2[_0x2de0b6]);
        }
        _0xe78a41(_0x1582b7);
      }),
      (this['socket'][_0x20872d(0x1ad)] = function (_0x5e8423) {
        const _0x3bb60c = _0x20872d;
        console[_0x3bb60c(0x186)](_0x3bb60c(0x1a4) + _0x5e8423), _0x9acd38(_0x5e8423);
      }),
      (this['socket'][_0x20872d(0x161)] = function (_0x315491) {
        const _0x5e2119 = _0x20872d;
        console[_0x5e2119(0x186)]('Websocket\x20closed:\x20' + JSON[_0x5e2119(0x1a6)](_0x315491));
      }),
      (this['send'] = function (_0x55af96, _0x327190) {
        this['waitForConnection'](function () {
          const _0x4b4acd = _0x31a5;
          _0xc294d0[_0x4b4acd(0x1c6)][_0x4b4acd(0x169)](_0x55af96),
            typeof _0x327190 !== _0x4b4acd(0x1af) && _0x327190();
        }, 0x1f4);
      }),
      (this['waitForConnection'] = function (_0x5a61ee, _0x21650c) {
        const _0x24b34c = _0x20872d;
        if (this[_0x24b34c(0x1c6)][_0x24b34c(0x1a3)] === 0x1) _0x5a61ee();
        else {
          const _0x5ca040 = this;
          setTimeout(function () {
            const _0x593914 = _0x24b34c;
            _0x5ca040[_0x593914(0x15c)](_0x5a61ee, _0x21650c);
          }, _0x21650c);
        }
      }),
      DEBUG == !![] && console[_0x20872d(0x186)](_0x20872d(0x19e) + _0x2e9899),
      this[_0x20872d(0x169)](_0x2e9899, function () {});
  });
}),
  (WSConnection[_0x562635(0x178)][_0x562635(0x16b)] = function () {
    'use strict';
    const _0xe866c9 = _0x562635;
    console[_0xe866c9(0x186)](_0xe866c9(0x1b0)), this[_0xe866c9(0x1c6)]['close']();
  });
function Vx820(_0x37db6f, _0x2a3ae9, _0x1c9dd4, _0x45ce41, _0x1e4476, _0x30dc8a) {
  const _0x227515 = _0x562635;
  VerifonePinpad[_0x227515(0x168)](this, _0x37db6f, _0x2a3ae9, _0x1c9dd4, _0x45ce41, _0x1e4476, _0x30dc8a, 'Vx820');
}
Vx820['prototype'] = VerifonePinpad['prototype'];
function Vx820c(_0x2a3936, _0x224a24, _0x10830b, _0x281cda, _0x1565ee, _0xe4d0f1) {
  const _0x4e74ff = _0x562635;
  VerifonePinpad[_0x4e74ff(0x168)](
    this,
    _0x2a3936,
    _0x224a24,
    _0x10830b,
    _0x281cda,
    _0x1565ee,
    _0xe4d0f1,
    _0x4e74ff(0x193)
  );
}
Vx820c['prototype'] = VerifonePinpad[_0x562635(0x178)];
function P400(_0x7d1dc3, _0x855df1, _0x14969a, _0x53d880, _0x4a3763, _0x261225) {
  const _0x1adacb = _0x562635;
  VerifonePinpad[_0x1adacb(0x168)](
    this,
    _0x7d1dc3,
    _0x855df1,
    _0x14969a,
    _0x53d880,
    _0x4a3763,
    _0x261225,
    _0x1adacb(0x1b9)
  );
}
P400[_0x562635(0x178)] = VerifonePinpad['prototype'];
function P400c(_0x533126, _0x57d715, _0x537ef3, _0x4063a1, _0x485e7b, _0x33f5af) {
  const _0x33ad1b = _0x562635;
  VerifonePinpad['call'](this, _0x533126, _0x57d715, _0x537ef3, _0x4063a1, _0x485e7b, _0x33f5af, _0x33ad1b(0x17a));
}
P400c[_0x562635(0x178)] = VerifonePinpad[_0x562635(0x178)];
function Vx810(_0x44a340, _0x1451e5, _0x37a767, _0x31e17f, _0x5ec6ca, _0x49e19e) {
  const _0x499bbc = _0x562635;
  VerifonePinpad[_0x499bbc(0x168)](
    this,
    _0x44a340,
    _0x1451e5,
    _0x37a767,
    _0x31e17f,
    _0x5ec6ca,
    _0x49e19e,
    _0x499bbc(0x1ba)
  );
}
Vx810[_0x562635(0x178)] = VerifonePinpad[_0x562635(0x178)];
function E265(_0x463e99, _0x1d2ac2, _0x34ffe7, _0x38724f, _0x36ab45, _0x18bc9c) {
  const _0x530ecc = _0x562635;
  VerifonePinpad[_0x530ecc(0x168)](
    this,
    _0x463e99,
    _0x1d2ac2,
    _0x34ffe7,
    _0x38724f,
    _0x36ab45,
    _0x18bc9c,
    _0x530ecc(0x16f)
  );
}
function _0x286f() {
  const _0x39a69b = [
    '768267OqIqMN',
    'setSpanish',
    'E355c',
    'substring',
    'getVersion',
    'text',
    'Vx820c',
    'Please,\x20enable\x20cookies\x20for\x20full\x20support\x20to\x20Banregio\x20Pinpad\x20JS\x20API',
    'Received\x20message\x20<=\x20',
    'onopen',
    'generarCriptograma',
    'parametros',
    '6371780ubosMX',
    'ejecutarCargaBinesExcepcion',
    'getText',
    'endTransaction',
    'com_parity',
    'Send\x20message\x20\x20\x20\x20\x20=>\x20',
    'desactivar',
    'setTime',
    'BNRG_BAUD_RATE',
    'key',
    'readyState',
    'WebSocket\x20error:\x20',
    'code',
    'stringify',
    'add',
    'executeKeyLoad',
    'executeExceptionBinsLoad',
    'funcion',
    'isEmpty',
    'toGMTString',
    'onerror',
    'indexOf',
    'undefined',
    'Disconnect\x20request\x20from\x20local\x20app\x20layer',
    'finalizarTransaccion',
    'E265c',
    'com_stopBits',
    'spanish',
    '1752305OQnxkD',
    '89796vOJpEF',
    '6520kWTUMb',
    'map',
    'P400',
    'Vx810',
    'hasOwnProperty',
    'E355',
    '6xALAMy',
    'split',
    'loadKey',
    'displayText',
    'obtenerVersion',
    'ejecutarTransaccion',
    'cancelKeyLoad',
    '2OOXZIs',
    'com_dataBits',
    'socket',
    'value',
    'parameter',
    'sesion',
    'desplegarTexto',
    'charAt',
    '64KEhTUm',
    'waitForConnection',
    'get',
    'cancelarCargaLlave',
    'length',
    'BNRG_PUERTO',
    'onclose',
    'disable',
    'com_port',
    'excep_codigo',
    'BNRG_PORT',
    'generateCryptogram',
    'com_baudRate',
    'call',
    'send',
    '11okrXwO',
    'disconnect',
    'cookie',
    'Pinpad\x20hasn\x27t\x20been\x20enabled!',
    'remove',
    'E265',
    'pp_api_session',
    'getTime',
    'BNRG_BITS_PARO',
    ';path=/',
    'iniciarTransaccion',
    'then',
    'enviar',
    'beginTransaction',
    'prototype',
    'activar',
    'P400c',
    '1121744WjUiPn',
    'setEnglish',
    'error',
    'cargarBinesExcepcion',
    '2830331sEgvgC',
    'connect',
    'data',
    'getInformation',
    'command',
    'enable',
    'ejecutarCargaLlave',
    'log',
    'executeTransaction',
    'model',
    'cargarLlave',
    'BNRG_STOP_BITS',
    'keys',
    'loadExceptionBins',
  ];
  _0x286f = function () {
    return _0x39a69b;
  };
  return _0x286f();
}
E265[_0x562635(0x178)] = VerifonePinpad[_0x562635(0x178)];
function E265c(_0x2b7c17, _0x204d70, _0xa8f779, _0x30f846, _0x2ae797, _0x1b5f4d) {
  const _0x1480bc = _0x562635;
  VerifonePinpad['call'](this, _0x2b7c17, _0x204d70, _0xa8f779, _0x30f846, _0x2ae797, _0x1b5f4d, _0x1480bc(0x1b2));
}
E265c[_0x562635(0x178)] = VerifonePinpad[_0x562635(0x178)];
function E355(_0x5b3fb5, _0x3bbd5a, _0x1b1240, _0x18da49, _0x408d84, _0x352baa) {
  const _0x47db30 = _0x562635;
  VerifonePinpad[_0x47db30(0x168)](
    this,
    _0x5b3fb5,
    _0x3bbd5a,
    _0x1b1240,
    _0x18da49,
    _0x408d84,
    _0x352baa,
    _0x47db30(0x1bc)
  );
}
E355[_0x562635(0x178)] = VerifonePinpad['prototype'];
function E355c(_0x5ea38c, _0x43629d, _0xcb449d, _0x51bba6, _0x3cc930, _0x17e44e) {
  const _0x3b177e = _0x562635;
  VerifonePinpad[_0x3b177e(0x168)](
    this,
    _0x5ea38c,
    _0x43629d,
    _0xcb449d,
    _0x51bba6,
    _0x3cc930,
    _0x17e44e,
    _0x3b177e(0x18f)
  );
}
E355c['prototype'] = VerifonePinpad[_0x562635(0x178)];
