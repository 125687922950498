import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { InjectionToken } from '@angular/core';

export const HAS_TOUCH_SUPPORT = new InjectionToken<boolean>('HasTouchSupportToken');

export const hasTouchSupportFactory = ({ defaultView }: Document, media: BreakpointObserver) => {
  if (defaultView?.navigator) {
    return (
      (defaultView.ontouchstart || defaultView.navigator.maxTouchPoints > 0) && media.isMatched(Breakpoints.Handset)
    );
  }

  return false;
};
