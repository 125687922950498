<h2 mat-dialog-title>
  {{ title }}
</h2>

<mat-dialog-content>
  <span>{{ message }}</span>
  <mat-progress-spinner
    color="primary"
    mode="indeterminate"
    diameter="50"
  />
</mat-dialog-content>
