import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@mazboletos/selling-point/core/config';
import { requiresToken, updatesSession } from '@mazboletos/shared/core/http/interceptors';
import { AnonymousSession, ApiResponse } from '@mazboletos/shared/domain';
import { Observable, map } from 'rxjs';

@Injectable()
export class SessionApiService {
  private config = inject<AppConfig>(APP_CONFIG);
  private http = inject(HttpClient);

  private apiUrl = `${this.config.apiUrl}/anonymous_sessions`;

  create(eventId: number) {
    return this.http
      .post<ApiResponse<AnonymousSession>>(this.apiUrl, { event_id: eventId }, { context: updatesSession() })
      .pipe(map((r) => r.data));
  }

  current() {
    return this.http
      .get<ApiResponse<unknown>>(`${this.apiUrl}/current?q=${Date.now()}`, {
        context: requiresToken(),
      })
      .pipe(map((r) => r.data));
  }

  destroy() {
    return this.http.delete<Observable<void>>(`${this.apiUrl}`, {
      context: requiresToken(),
    });
  }

  paymentConfirmed(step: 'tokenized' | 'finalized') {
    return this.http.put<Observable<void>>(
      `${this.apiUrl}/payment_step_confirmed`,
      {
        step,
      },
      {
        context: requiresToken(),
      }
    );
  }
}
