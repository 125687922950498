import { Component } from '@angular/core';
import { ShellComponent } from '@mazboletos/selling-point/shell/feature';

@Component({
  selector: 'maz-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [ShellComponent],
})
export class AppComponent {
  title = 'selling-point';
}
