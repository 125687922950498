import { isPlatformServer } from '@angular/common';
import { Injectable, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@mazboletos/shared/common/notification/notification';
import { RouteUtils } from '@mazboletos/shared/utils/route';
import { Observable, catchError, finalize, map, of } from 'rxjs';
import { SessionApiService } from './session-api.service';

@Injectable()
export class SessionResolver {
  private api = inject(SessionApiService);
  private router = inject(Router);
  private platformId = inject(PLATFORM_ID);
  private notification = inject(NotificationService, { optional: true })!;
  private zone = inject(NgZone);

  resolve(): Observable<boolean | unknown> {
    this.notification?.showLoading('Verificando sesión', 'Por favor espera.');

    if (isPlatformServer(this.platformId)) {
      return of(true);
    }

    return this.api.current().pipe(
      map((session) => {
        if (session) {
          return session;
        } else {
          this.router.navigateByUrl(RouteUtils.Default);
          return false;
        }
      }),
      catchError(() => {
        this.router.navigateByUrl(RouteUtils.Default);
        return of(false);
      }),
      finalize(() => {
        this.notification?.hide();
      })
    );
  }
}
